// Inspired by: https://codepen.io/AllThingsSmitty/pen/jommGQ
.container {
  & {
    font-family:
      'SanFrancisco',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      Roboto,
      Oxygen-Sans,
      Ubuntu,
      Cantarell,
      'Helvetica Neue',
      sans-serif;
    font-weight: normal;

    margin: 0 auto;
    padding: 1rem;
    max-width: 100%;
    width: 100%;

    --messagethread-bg: var(--bgColor-muted);

    --message-sent-fg: #fff;
    --message-sent-bg: #248bf5;

    --message-received-fg: #000;
    --message-received-bg: #e5e5ea;
  }

  $avatarSize: var(--avatarSize-regular, 20px);

  > ul {
    & {
      list-style: none;
      background-color: var(--messagethread-bg);
      display: flex;
      flex-direction: column;
      margin: 0 auto 1rem;
      padding: 0.5rem 1.5rem;
    }

    &.with-avatar {
      padding-left: calc(1.5rem + $avatarSize);
      padding-right: calc(1.5rem + $avatarSize);
    }
  }

  .sms {
    --message-sent-bg: #32c349; // #6acc46; // #30d158;
  }

  .imessage {
    --message-sent-bg: #007aff;
  }

  .placeholder {
    color: var(--message-fg);
    font-variant: all-small-caps;
  }

  // Does it follow a message of a different type?
  .sent:not(.sent + .sent),
  .received:not(.received + .received) {
    &:not(:first-child) {
      margin-top: 0;
    }

    &:not(:last-child) {
      margin-bottom: 0;
    }

    // Need to figure this selector out…hard to match "not followed by".
    // &::before, &::after {
    //   display: none;
    // }
  }

  p,
  li {
    &.sent,
    &.received {
      border-radius: 1.15rem;
      line-height: 1.25;
      max-width: 450px; // 75% * 600px
      padding: 0.5rem 0.875rem;
      position: relative;
      word-wrap: break-word;

      margin: 0.5rem 0;
      width: fit-content;

      color: var(--message-fg);
      background-color: var(--message-bg);

      // Don't show tail if message is empty, but do show the avatar.
      &.empty {
        background-color: transparent;

        &::before,
        &::after {
          content: none;
          display: none;
        }
      }

      &::before,
      &::after {
        bottom: -0.1rem;
        content: '';
        height: 1rem;
        position: absolute;
      }

      &::before {
        transform: translate(0, -0.1rem);
      }

      &::after {
        background-color: var(--messagethread-bg);
        transform: translate(-30px, -2px);
        width: 10px;
      }

      .avatar {
        z-index: 1;
        position: absolute;
        bottom: 0;
      }
    }

    &.sent {
      --message-bg: var(--message-sent-bg);
      --message-fg: var(--message-sent-fg);

      & {
        align-self: flex-end;
      }

      &::before {
        border-bottom-left-radius: 0.8rem 0.7rem;
        border-right: 1rem solid var(--message-bg);
        right: -0.35rem;
      }

      &::after {
        border-bottom-left-radius: 0.5rem;
        right: -40px;
      }

      .avatar {
        right: calc(-1.5 * $avatarSize);
      }
    }

    &.typing,
    &.typing.received {
      &,
      [data-color-mode='light'] & {
        --message-bg: var(--message-received-bg);
        --typing-dot1: #a7a6ab;
        --typing-dot2: #8c8c90;
      }

      @media (prefers-color-scheme: dark) {
        --message-bg: #1b1c1e;
        --typing-dot1: #757575;
        --typing-dot2: #414143;
      }

      .dot {
        color: var(--typing-dot2);
        background-color: var(--typing-dot2);

        &:first-child {
          color: var(--typing-dot1);
          background-color: var(--typing-dot1);
        }
      }
    }

    &.received {
      --message-bg: var(--message-received-bg);
      --message-fg: var(--message-received-fg);

      & {
        align-items: flex-start;
      }

      &::before {
        border-bottom-right-radius: 0.8rem 0.7rem;
        border-left: 1rem solid var(--message-bg);
        left: -0.35rem;
      }

      &::after {
        border-bottom-right-radius: 0.5rem;
        left: 20px;
      }

      .avatar {
        left: calc(-1.5 * $avatarSize);
      }
    }
  }
}
